import React from 'react';
import styled, { css } from 'styled-components';

export default styled.div`
  background-color: #ffffff;
  box-shadow: 0 5px 20px rgba(25, 17, 34, 0.1);
  border-radius: 1px;
  margin: 150px 50px;

  ${props => props.theme.mobile && css`
    margin: 100px 20px;  
  `}
`;
