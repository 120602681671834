import React from 'react';
import cx from 'classnames';

import Project from '../../../themes/project';
import Card from '../../../themes/project/Card';
import Container from '../../../components/Container';
import Right from 'react-feather/dist/icons/chevrons-right';

import styles from './styles.module.scss';

import project from '../../../../config/projects';

function ReactNativeFirebase() {
  return (
    <div className={styles.background}>
      <Project config={project.byKey.rnfb}>
        <Container>
          <div className={styles.hero}>
            <h1>React Native Firebase</h1>
            <h2>Simple Firebase integration for React Native</h2>
            <div role="button" className={styles.button}>
              Get Started &nbsp; <Right />
            </div>
          </div>
        </Container>
        <Card>
          <div className={styles.card}>
            <div className={cx(styles.block, styles.starters)}>
              <div>
                <h3>Native Firebase module integration</h3>
                <p>
                  React Native Firebase provides full, Web API compliant
                  integration for over 15{' '}
                  <a href="https://firebase.google.com" target="_blank">
                    Firebase
                  </a>{' '}
                  modules for both iOS and Android.
                </p>
                <p>Etc</p>
              </div>
              <div>
                <h3>Comprehensive documentation</h3>
                <p>
                  Each module has full API reference coverage, with the
                  sourcecode being fully{' '}
                  <a href="https://www.typescriptlang.org" target="_blank">
                    Typescript
                  </a>{' '}
                  and{' '}
                  <a href="https://flow.org/" target="_blank">
                    Flow
                  </a>{' '}
                  compliant. If you're new to React Native or Firebase, you'll
                  also find our guides handy. (wording)
                </p>
              </div>
              <div>
                <h3>Community at the heart</h3>
                <p>Thanks to the awesome Open Source community...</p>
                <p>Discord, Stack overflow etc</p>
              </div>
            </div>
            <div className={styles.block}>
              <div>
                <h3>Need a Mac to build your iOS app?</h3>
                <p>
                  To build React Native apps for iOS you'll need access to a Mac
                  to be able to use Xcode and the iOS device simulators.
                  <br />
                  <br />
                  We've teamed up with MacStadium - the leading provider of
                  cloud hosted Mac infrastructure - to provide a special
                  discount offer to all React Native Firebase developers.
                </p>
              </div>
            </div>
          </div>
        </Card>
        <Card>
          <div className={styles.card}>
            <div className={styles.block}>
              <div>
                <h3>Support Modules</h3>
                <p>
                  React Native Firebase supports a vast majority of Firebase,
                  each module is optional - only install what you require. Our
                  implementation replicates the official Web SDK where possible
                  and supports both Android and iOS.
                </p>

                <div className={styles.modules}>
                  <div>
                    <h4>Admob</h4>
                    <p>
                      Integrate native Banners, Interstitials, Rewarded Videos &
                      Native Express adverts into your app with simple
                      components or API calls. You'll also enjoy the benefits of
                      in-depth device & user targeting with no extra
                      configuration.
                    </p>
                  </div>

                  <div>
                    <h4>Analytics</h4>
                    <p>
                      With minimal setup, you'll instantly start generating
                      detailed analytical information of your users, on both
                      Android & iOS; including device types, demographics,
                      sessions, cohort analysis and loads more.
                    </p>
                  </div>

                  <div>
                    <h4>Authentication</h4>
                    <p>
                      Firebase Authentication provides easy-to-use methods to
                      authenticate users to your app. We support authentication
                      using passwords, phone numbers, popular federated identity
                      providers (with additional RN modules) like Google,
                      Facebook and Twitter, and more.
                    </p>
                  </div>

                  <div>
                    <h4>Cloud Messaging</h4>
                    <p>
                      Send messages and notifications to users across
                      platforms—Android, iOS, and the web—for free. Messages can
                      be sent to single devices, groups of devices, or specific
                      topics or user segments. Firebase Cloud Messaging (FCM)
                      scales to even the largest apps, delivering hundreds of
                      billions of messages per day.
                    </p>
                  </div>

                  <div>
                    <h4>Crash Reporting</h4>
                    <p>
                      Seamlessly report crashes directly to your Firebase
                      console. Works across both iOS & Android with a simple JS
                      api which allows captures of errors including their full
                      JS stack trace.
                    </p>
                  </div>

                  <div>
                    <h4>Performance Monitoring</h4>
                    <p>
                      Identify performance bottlenecks of your device in your
                      Firebase console. Take advantage of automatic traces such
                      as app start time or create your own with a simple JS API.
                    </p>
                  </div>

                  <div>
                    <h4>Realtime Database</h4>
                    <p>
                      Store and sync data between users and devices in realtime
                      using a cloud-hosted, NoSQL database with full support for
                      offline persistence and transactions.
                    </p>
                  </div>

                  <div>
                    <h4>Remote Config</h4>
                    <p>
                      Customize how your app renders for each user. Change the
                      look and feel, roll out features gradually, run A/B tests,
                      deliver customized content to certain users, or make other
                      updates without deploying a new version—all from the
                      Firebase console.
                    </p>
                  </div>

                  <div>
                    <h4>Cloud Storage</h4>
                    <p>
                      Store and share user-generated content like images, audio,
                      and video using the native Android/iOS Firebase SDK's with
                      a Web SDK like JS api.
                    </p>
                  </div>

                  <div>
                    <h4>Cloud Firestore</h4>
                    <p>
                      Store and sync data between users and devices - at global
                      scale - using a cloud-hosted, noSQL database. Cloud
                      Firestore gives you live synchronization and offline
                      support along with efficient data queries.
                    </p>
                  </div>

                  <div>
                    <h4>Dynamic Links</h4>
                    <p>
                      Dynamic Links are smart URLs that allow you to send
                      existing and potential users to any location within your
                      iOS or Android app.
                    </p>
                  </div>

                  <div>
                    <h4>Machine Learning (coming soon)</h4>
                    <p>
                      ML Kit lets you bring powerful machine learning features
                      to your app whether it's for Android or iOS, and whether
                      you're an experienced machine learning developer or you're
                      just getting started.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </Project>
    </div>
  );
}

export default ReactNativeFirebase;
